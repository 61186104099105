import React, { useEffect } from "react";
import dayjs from "dayjs";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import Logo from "../../../../assets/images/ccLogo.png";
import ApplyModal from "./ApplyModal";
import { AmpAPI } from "@thatsclutch/shared";

export default function MatchDetails({ matchID }) {
  const [match, setMatch] = React.useState({});
  const [client, setClient] = React.useState({});
  const [project, setProject] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getMatchDetails();
  }, []);

  async function getMatchDetails() {
    console.log("fetching details");
    try {
      const data = await AmpAPI.get("CampusAPI", "/match/" + matchID, {});
      console.log(data);
      setMatch(data.match);
      setClient(data.match.client);
      setProject(data.brief);
      // make a request to server
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    console.log("Submitted");
    try {
      setLoading(true);

      // make a request to server
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const Detail = ({ title, value }) => (
    <div className="flex justify-center flex-1">
      <div className="flex flex-col items-center space-y-2">
        <div className="text-sm text-center text-slate-400">{title}</div>
        <div className="font-medium text-center">{value}</div>
      </div>
    </div>
  );
  return loading ? (
    <div className="flex flex-col w-full h-screen space-y-8 bg-gray-50">
      <div className="flex items-center justify-between p-4 px-8 bg-white shadow">
        <img src={Logo} className="object-cover " height={50} width={50} />
        <div className="flex items-center space-x-4">
          <button className="p-3 rounded-full cursor-pointer hover:bg-gray-50">
            <QuestionMarkCircleIcon className="w-6 h-6 text-slate-600" />
          </button>
          <Link className="text-center" to="/app/dashboard">
            <button className="px-6 py-3 text-base text-center text-black capitalize transition-opacity cursor-pointer bg-slate-100 whitespace-nowrap rounded-xl hover:opacity-50">
              Back to studio
            </button>
          </Link>
        </div>
      </div>
      {/* Skeleton */}
      <div className="mx-6 bg-white shadow-2xl md:w-3/4 md:mx-auto shadow-slate-200/50 rounded-2xl">
        <div className="h-48 p-6 space-y-4 text-center text-slate-500">
          <div className="flex items-center justify-between">
            <div className="flex pt-2 space-x-6">
              <div className="flex items-center space-x-4">
                <div className="space-y-1">
                  <div className="text-3xl font-medium capitalize rounded-lg text-slate-50 bg-slate-50">
                    Loading profile...
                  </div>
                  <div className="text-xs rounded-lg text-slate-50 bg-slate-50">
                    they/them
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col w-full h-screen">
      <div className="flex items-center justify-between p-4 px-8 shadow-xl">
        <img src={Logo} className="object-cover " height={50} width={50} />
        <div className="flex items-center space-x-4">
          <button className="p-3 rounded-full cursor-pointer hover:bg-gray-50">
            <QuestionMarkCircleIcon className="w-6 h-6 text-slate-600" />
          </button>

          <Link className="text-center" to="/app/dashboard">
            <button className="px-6 py-3 text-base text-center text-black capitalize transition-opacity cursor-pointer bg-slate-100 whitespace-nowrap rounded-xl hover:opacity-50">
              Back to studio
            </button>
          </Link>
        </div>
      </div>
      <div className="flex-1 py-8 space-y-8 overflow-scroll bg-gray-50">
        {/* Client Info */}
        <div className="max-w-3xl mx-6 bg-white shadow-2xl md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="flex flex-col items-center justify-between p-8 space-x-0 space-y-8 md:flex-row md:space-x-6 md:space-y-0">
            <div className="space-y-2">
              <div className="text-2xl font-medium">{project?.serviceName}</div>
              <div className="text-lg font-normal text-center text-gray-600 capitalize md:text-start">
                {"By " + client.brand}
              </div>
            </div>
            <div className="flex items-center w-full space-x-4 md:w-min">
              {loading && (
                <div className="flex items-center justify-center">
                  <svg
                    className="z-10 w-5 h-5 text-black animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
              {match.status === "DRAFT" ? (
                <ApplyModal onComplete={getMatchDetails} matchID={match.id}>
                  <button className="w-full px-6 py-3 text-base text-center text-black capitalize transition-opacity bg-green-300 cursor-pointer whitespace-nowrap rounded-xl hover:opacity-50">
                    Apply for collab
                  </button>
                </ApplyModal>
              ) : match.status === "ACTIVE" ? (
                <div className="flex items-center justify-center w-full space-x-4">
                  <CheckCircleIcon className="w-5 h-5 text-green-400" />
                  <div className="py-3 text-base text-center text-black capitalize transition-opacity whitespace-nowrap ">
                    Submitted
                  </div>
                </div>
              ) : (
                <div className="w-full px-6 py-3 text-base text-center text-black capitalize transition-opacity whitespace-nowrap ">
                  Opportunity Closed
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Project Scope */}
        <div className="max-w-3xl p-4 pb-8 mx-6 bg-white shadow-2xl md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="flex p-6 space-x-4 justify-evenly">
            <Detail
              title="Price"
              value={"$" + Math.round(match?.price)} // TODO: divide by 100
            />
            <Detail title="# of assets" value={match?.deliverableAmount} />

            {project?.startDate && (
              <Detail
                title="Starting"
                value={dayjs.utc(project?.startDate).format("MMMM Do")}
              />
            )}
          </div>
          <div className="px-4 pt-8 pb-2 space-y-8 leading-normal border-t md:px-8 border-slate-100">
            <div className="space-y-2 overflow-hidden text-ellipsis">
              <div className="text-lg font-normal text-gray-600 first-letter:capitalize">
                Scope
              </div>
              <div className="leading-normal whitespace-pre-line text-slate-600">
                {project?.details}
              </div>
            </div>

            {project?.requirements && (
              <div className="space-y-2 overflow-hidden text-ellipsis">
                <div className="text-lg font-normal text-gray-600 first-letter:capitalize">
                  Looking for
                </div>
                <div className="leading-normal whitespace-pre-line text-slate-600">
                  {project?.requirements}
                </div>
              </div>
            )}
            {client?.businessLinks && (
              <div className="space-y-2 overflow-hidden text-ellipsis">
                <div className="text-lg font-normal text-gray-600 first-letter:capitalize">
                  Website
                </div>
                <a
                  className="text-base underline text-slate-600"
                  href={client.businessLinks}
                  target="_blank"
                >
                  {client.businessLinks}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
