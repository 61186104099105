import React, { useState, useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import { Auth } from "aws-amplify";
import { Button, Form, Input } from "antd";
import { useToasts } from "react-toast-notifications";
import useAuth from "../../components/Auth/useAuth";
import MatchDetails from "../../components/Screens/Dashboard/SelfMatch";
import { configureAmplify } from "@thatsclutch/shared";
import Logo from "../../assets/images/ccLogo.png";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";

export default function Match({ location: { pathname } }) {
  configureAmplify(process.env.GATSBY_AMP_ENV);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);
  let matchID = "";
  if (typeof window === "undefined" || typeof window.location === "undefined") {
    console.log("window or window.location is undefined.");
  } else {
    console.log(window.location.href);
    const myURL = new URL(window.location.href);
    console.log(myURL.searchParams.get("matchID"));
    matchID = myURL.searchParams.get("matchID");
  }
  // const [currUser, userLoading, logoutUser, getUser] = useAuth();
  const [currUser, setCurrUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setLoadingUser(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const userGroups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      const userInfo = {
        ...user.attributes,
        username: user.username,
        userGroups,
      };

      console.log("groups:", userGroups);
      if (!userGroups.includes("admins") && !userGroups.includes("creators")) {
        throw "not creator or admin";
      }
      setCurrUser(userInfo);
      setLoadingUser(false);
      return true;
    } catch (e) {
      return false;
    }
  };

  const login = async (values) => {
    const username = values.username?.trim()?.toLowerCase();
    try {
      setLoading(true);
      const userSnapshot = await Auth.signIn(username, values.password);
      if (userSnapshot.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("/app/set-password");
      }
      const success = await getUser();
      console.log(success);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loadingUser)
    return (
      <div className="flex flex-col w-full h-screen space-y-8 bg-gray-50">
        <div className="flex items-center justify-between p-4 px-8 bg-white shadow">
          <img src={Logo} className="object-cover " height={50} width={50} />
          <div className="flex items-center space-x-4">
            <button className="p-3 rounded-full cursor-pointer hover:bg-gray-50">
              <QuestionMarkCircleIcon className="w-6 h-6 text-slate-600" />
            </button>
            <Link className="text-center" to="/app/dashboard">
              <button className="px-6 py-3 text-base text-center text-black capitalize transition-opacity cursor-pointer bg-slate-100 whitespace-nowrap rounded-xl hover:opacity-50">
                Back to studio
              </button>
            </Link>
          </div>
        </div>
        <div className="p-8 mx-6 bg-white shadow-2xl md:w-3/4 md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="flex items-center justify-center">
            <svg
              className="z-10 w-5 h-5 text-black animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    );
  // authenticated user
  if (currUser) return <MatchDetails matchID={matchID} />;
  // unauthenticated user
  else
    return (
      <div className="flex flex-col w-full h-screen space-y-8 bg-gray-50">
        <div className="flex items-center justify-between p-4 px-8 bg-white shadow">
          <img src={Logo} className="object-cover " height={50} width={50} />
          <div className="flex items-center space-x-4">
            <button className="p-3 rounded-full cursor-pointer hover:bg-gray-50">
              <QuestionMarkCircleIcon className="w-6 h-6 text-slate-600" />
            </button>
            <Link className="text-center" to="/app/dashboard">
              <button className="px-6 py-3 text-base text-center text-black capitalize transition-opacity cursor-pointer bg-slate-100 whitespace-nowrap rounded-xl hover:opacity-50">
                Back to studio
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col h-full pt-24">
          <div className="p-8 mx-4 bg-white shadow-2xl md:p-4 md:w-1/2 md:mx-auto shadow-slate-200/50 rounded-2xl">
            <div className="mb-6 space-y-4">
              <div>Clutch Studio</div>
              <div className="text-xl font-semibold md:text-3xl">
                Log in to view
              </div>
            </div>
            {showResend && (
              <div className="p-2 px-4 text-sm text-yellow-600 rounded bg-yellow-400/20">
                Temporary password expired. A new one has been sent to your
                email.
              </div>
            )}

            <Form
              form={form}
              initialValues={{
                terms: false,
              }}
              layout="vertical"
              onFinish={login}
              size="large"
              requiredMark={false}
            >
              <Form.Item
                placeholder="Email"
                name="username"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  { required: true, message: "Please enter your Username!" },
                ]}
              >
                <Input
                  name="username"
                  placeholder="example@domain.com"
                  style={{ textTransform: "lowercase" }}
                />
              </Form.Item>

              <Form.Item
                placeholder="Password"
                name="password"
                rules={[{ required: true }]}
                extra={
                  <div className="flex justify-end mt-1 mb-4 ">
                    <a href="/app/forgotpassword" className="text-[#64D698]">
                      Forgot Password?
                    </a>
                  </div>
                }
              >
                {/* <input name="password" type="password" className="w-full p-3 rounded-full outline-none ring-1 ring-gray-400" /> */}
                <Input.Password name="password" placeholder="Password..." />
              </Form.Item>

              <div className="">
                <Button
                  name="submit"
                  style={{ background: "#30DF87", border: "none" }}
                  shape="round"
                  size="large"
                  className="w-32 bg-[#30DF87]"
                  loading={isLoading}
                  htmlType="submit"
                >
                  Sign In
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
}
